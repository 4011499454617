import { useEffect, useRef, useState } from "react";
import { SongList } from "./components/songList.js";
import spotify from "./lib/spotify.js";
import { Player } from "./components/player.js";
import { SearchInput } from "./components/SearchInput.js";
import { Pagination } from "./components/Pagination.js";

const limit = 20;

export default function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [popularSongs, setPopularSongs] = useState([]);
  const [selectedSong, setSelectedSong] = useState();
  const [isPlay, setIsPlay] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [searchedSongs, setSearchedSongs] = useState();
  const [page, setPage] = useState(1);
  const [hasNext, sethasNext] = useState(false);
  const [hasPrev, sethasPrev] = useState(false);

  const isSearchedResult = searchedSongs != null;

  const audioRef = useRef(null);

  useEffect(() => {
    fetchPopularSongs();
  }, []);

  const fetchPopularSongs = async () => {
    setIsLoading(true);
    const result = await spotify.getPopularSongs();
    const songArr = result.items.map((item) => {
      return item.track;
    });
    setPopularSongs(songArr);
    setIsLoading(false);
  };

  const handleSelectedSong = async (song) => {
    setSelectedSong(song);
    if (song.preview_url != null) {
      audioRef.current.src = song.preview_url;
      playSong();
    } else {
      stopSong();
    }
  };

  const playSong = () => {
    audioRef.current.play();
    setIsPlay(true);
  };
  const stopSong = () => {
    audioRef.current.pause();
    setIsPlay(false);
  };

  const toggleSong = () => {
    if (isPlay) {
      stopSong();
    } else {
      playSong();
    }
  };

  const handleInputChange = (e) => {
    setKeyword(e.target.value);
  };

  const searchSongs = async (page) => {
    setIsLoading(true);
    const offset = parseInt(page) ? (parseInt(page) - 1) * 20 : 0;
    const result = await spotify.searchSongs(keyword, limit, offset);
    console.log(result);
    sethasNext(result.next != null);
    sethasPrev(result.previous != null);
    setSearchedSongs(result.items);
    setIsLoading(false);
  };

  const moveToNext = async () => {
    setPage((prev) => prev + 1);
    await searchSongs(page);
  };

  const moveToBack = async () => {
    setPage((prev) => prev - 1);
    await searchSongs(page);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white">
      <main className="flex-1 p-8 mb-20">
        <header className="flex justify-between items-center mb-10">
          <h1 className="text-4xl font-bold">Music App</h1>
        </header>
        <SearchInput
          keyword={keyword}
          handleInputChange={handleInputChange}
          onSubmit={searchSongs}
        />
        <section>
          <h2 className="text-2xl font-semibold mb-5">
            {isSearchedResult ? "Searched Result" : "Popular Songs"}
          </h2>
          <SongList
            isLoading={isLoading}
            popularSongs={isSearchedResult ? searchedSongs : popularSongs}
            onClickSong={handleSelectedSong}
          />
          {isSearchedResult && (
            <Pagination
              moveToNext={hasNext ? moveToNext : null}
              moveToBack={hasPrev ? moveToBack : null}
            />
          )}
        </section>
      </main>
      {selectedSong != null && (
        <Player
          song={selectedSong}
          onClickButton={toggleSong}
          isPlay={isPlay}
        />
      )}

      <audio ref={audioRef} />
    </div>
  );
}
